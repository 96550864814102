
import { useState, useEffect } from 'react';
import Table from 'Components/Table/TableWrap';
import Modal from 'Components/Modal';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { useOutletContext } from "react-router-dom";
import NewUser from './New';

const Users = () => {
    const [showModal, setShowModal] = useState(false);
    const [setTitle] = useOutletContext();
    const [refresh, setRefresh] = useState(false);

    const closeModal = () => {
      setShowModal(false);
      setRefresh(true);
    }

    useEffect(() => {
      setTitle('Пользователи');
      setRefresh(false);
    }, [refresh]);

    return (
      <>
        {!refresh &&
          <Table 
            filters={
              {
                id: 'id',
                name: 'Имя', 
                email: 'email',
              } 
            }
            columns={
              { 
                id: {
                  name: 'id',
                  orderable: true,
                },
                first_name: {
                  name: 'Имя',
                  orderable: true,
                }, 
                email: {
                  name: 'Почта',
                  orderable: true,
                }, 
                // roles: {
                //   name: 'Роли', 
                //   orderable: false,
                // },
                // services: {
                //   name: 'Сервисы', 
                //   orderable: false,
                // },
                last: {
                  name: ''
                }
              }
            }

            dataUrl = 'admin/users/'
            cells = {['id', 'first_name', 'email']}
            deleteItems = {true}
            editItems = {true}
          />
        }

        <PrimaryButton className='mt-5' onClick={() => setShowModal(true)}>
          Создать пользователя
        </PrimaryButton> 
        <Modal show={showModal} onClose={closeModal}>
          <NewUser closeModal={closeModal} />
        </Modal>
      </>
    );
}

export default Users;
