import { Link } from 'react-router-dom';
import BoxedElement from '../../Components/BoxedElement';

const TablePagination = ({links, setPage}) => {

  return (
    <BoxedElement className='mt-5 p-2'>
      <div className='flex justify-center'>
        {
          links.map((link, i) => {
            if(link.label == '&laquo; Previous') link.label = '<';
            if(link.label == 'Next &raquo;') link.label = '>';

            return (
              <Link 
                key={i}
                className={`mx-2 p-2 rounded border ${link.active ? ' border-gray-100 bg-zinc-200' : 'border-transparent'} ${link.url ? '' : 'opacity-10 pointer-events-none'}`} 
                onClick={() => setPage(link.url)}
              >{link.label}</Link>
            );
          })
        }
      </div>
    </BoxedElement>
  )
}

export default TablePagination