import BoxedElement from 'Components/BoxedElement';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import InputLabel from 'Components/InputLabel';
import ServiceProperty from './Partials/ServiceProperty';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import { useForm } from "react-hook-form";
import { useParams, useOutletContext } from 'react-router-dom';
import useApi from 'Hooks/useApi';
import { useState, useEffect } from 'react';
import useNotification from 'Hooks/useNotification';
import Loader from 'Components/Loader';

const EditService = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [formErrors, setFormErrors] = useState('');
  const [data, setData] = useState(null);
  const [propertiesForDelete, setPropertiesForDelete] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  
  const { setMessage } = useNotification();
  const api = useApi();
  const { id } = useParams();
  const [setTitle] = useOutletContext();

  const addProperty = () => {
    setData(data => ({
      ...data,
      properties: [
        ...data.properties,
        {
          'name': '',
          'code': '',
          'value': '',
          'new': true
        }
      ]
    }));
  }

  const changeProperty = (key, input, value) => {
    setData(data => ({
      ...data,
      properties: data.properties.map((prop, i) => 
        i === key ? {
          ...prop,
          [input]: value
        } : prop
      )
    }));
  }

  const deleteProperty = (key, isNew, id) => {
    if(!isNew) {
      setPropertiesForDelete(propertiesForDelete => ({...propertiesForDelete, id}))
    }

    setData(data => ({
      ...data,
      properties: data.properties.filter((prop, k) => k !== key)
    }));
  }

  const getService = async () => {
    try {
        const response = await api.get('admin/services/'+id);
        setData(response.data);
        console.log(data);
        setLoadingData(false);
    } catch (err) {
        console.error(err);
    }
  }

  useEffect(() => {
    setTitle('Сервисы');
    getService();
  }, [])

  const submit = (formData) => {
    api.patch('admin/services/'+id, {
      'service': formData,
      'properties': data.properties,
      'propertiesForDelete': propertiesForDelete
    }).then((response) => {
      setMessage(response.data);
      console.log(response);
    }).catch(function (error) {
      console.log(error);
      setFormErrors(error);
    });
  };

  return (
    <>
      {!loadingData ?
        <form  onSubmit={handleSubmit(submit)}>
          <div className='flex'>
            <BoxedElement className={'p-5 w-1/3 mr-5'}>
              <h2>Основное</h2>
                <div className='mt-4'>
                  <InputLabel>Название</InputLabel>
                  <input 
                    type='text' 
                    required 
                    name="name" 
                    className={' w-full'} 
                    defaultValue={data.service.name} 
                    {...register("name")}
                  />
                </div>
                <div className='mt-4'>
                  <InputLabel>Описание</InputLabel>
                  <textarea 
                    name="description" 
                    className={' w-full'} 
                    defaultValue={data.service.description} 
                    {...register("description")}
                  ></textarea>
                </div>
                <div className='mt-4'>
                  <InputLabel>Алиас</InputLabel>
                  <input 
                    type='text'
                    name="alias" 
                    required 
                    className={' w-full'} 
                    defaultValue={data.service.alias} 
                    {...register("alias")}
                  />
                </div>
                <div className='mt-4'>
                  <InputLabel>Стоимость</InputLabel>
                  <input 
                    type='text'
                    name="cost" 
                    className={' w-full'} 
                    defaultValue={data.service.cost} 
                    {...register("cost")}
                  />
                </div>
                
            </BoxedElement>
            <BoxedElement  className={'p-5 w-2/3'}>
              <h2>Свойства</h2>
              { data?.properties 
                && 
                data.properties.map((property, i) => 
                  <ServiceProperty 
                    id={i} 
                    key={i} 
                    changeProperty={changeProperty}
                    deleteProperty={deleteProperty}
                    property={property}
                  />
                )
              }
              <SecondaryButton className='mt-5' onClick={() => addProperty()}>Добавить свойство</SecondaryButton>
            </BoxedElement>
          </div>
          <PrimaryButton className='mt-4'>Применить</PrimaryButton>
        </form>
        :
        <Loader></Loader>
      }
    </>
  )
}

export default EditService