import PrimaryButton from 'Components/Buttons/PrimaryButton'
import InputError from 'Components/InputError';
import { useForm, SubmitHandler } from "react-hook-form";
import useApi from 'Hooks/useApi';
import { useState } from 'react';

const NewUser = ({closeModal}) => {
  const [formErrors, setFormErrors] = useState('');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const api = useApi();

  const submit = (data) => {
    api.post('admin/users/', data).then((response) => {
      closeModal();
    }).catch(function (error) {
      console.log(error);
      setFormErrors(error);
    });
  };

  return (
    <form className='p-5 text-center flex flex-col justify-center'  onSubmit={handleSubmit(submit)}>
      <h2>Новый пользователь</h2>
      <input 
        type="text" 
        id='name'
        autoComplete="name"
        name="name"
        className='mt-2 block'
        placeholder="Имя"
        {...register("username")}
        required
      />
      <input  
        type="text" 
        id='email'
        name="email"
        className='mt-2 block'
        {...register("email")}
        placeholder="Почта"
        required
      />
      <input  
        type="password" 
        id='password'
        autoComplete="new-password"
        name="password"
        {...register("password")}
        required
        className='mt-2 block'
        placeholder="Пароль"
      />
      <input
        id="password_confirmation"
        type="password"
        name="password_confirmation"
        className="mt-2 block w-full"
        autoComplete="new-password"
        placeholder="Подтверждение пароля"
        {...register("confirm_password")}
        required
      />
      {formErrors && <InputError message={formErrors} className="mt-2" />}
      <PrimaryButton type="submit" className='mt-2 block'>Создать</PrimaryButton>
    </form>
  )
}

export default NewUser