import { useState, useEffect } from "react";
import BoxedElement from 'Components/BoxedElement';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import useApi from 'Hooks/useApi';
import Loader from 'Components/Loader';
import BoxedElementHeader from 'Components/BoxedElementHeader';
import { useOutletContext } from "react-router-dom";

const Services = () => {
    const [services, setServices] = useState(null);
    const [userServices, setUserServices] = useState({});
    const api = useApi();
    const [loadingData, setLoadingData] = useState(true);
    const [refreshData, setRefreshData] = useState(false);
    const [setTitle] = useOutletContext();
    const getServices = async () => {
        try {
            const servicesResponse = await api.get('/services/');
            //const userServicesResponse = await api.get('user/services/');
            
            let userServicesIds = [];
            // userServicesResponse.data.map((userService) => {
            //     userServicesIds.push(userService.id);
            // });
            setServices(servicesResponse.data);
            setUserServices(userServicesIds);
            setLoadingData(false);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        setTitle('Сервисы');
        getServices();
    }, [refreshData])
    
    const handleAttachService = async (id) => {
        try {
            await api.patch('/services/'+id+'/').then(setRefreshData(!refreshData));
        } catch (error) {
            console.log(error);
        }
    }
    const handleDetachService = async (id) => {
        try {
            await api.patch('/services/'+id+'/').then(setRefreshData(!refreshData));
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            {!loadingData ?
                services.length != 0 ?
                    <div className='flex'>
                        {
                            services.map((service) => 
                                <BoxedElement className={'w-3/12 text-center m-2 flex flex-col'} key={service.id}>
                                    <BoxedElementHeader>{service.name}</BoxedElementHeader>
                                        <div className='text-slate-400 mb-4 p-5'>{service.description}</div>
                                        <div className='p-5 mt-auto'>
                                            {
                                                userServices.includes(service.id) ?
                                                    <PrimaryButton onClick={() => handleDetachService(service.id)}>Отключить</PrimaryButton>
                                                :
                                                    <PrimaryButton onClick={() => handleAttachService(service.id)}>Подключить</PrimaryButton>
                                            }
                                        </div>
                                </BoxedElement>
                            )
                        }
                    </div>
                :
                    <p>Нет сервисов</p>
            :
                <Loader></Loader>
            }
        </div>
    );
}

export default Services;