import { useState, useEffect } from 'react';
import useApi from '../../Hooks/useApi';

const TableHeader = ({columns, url}) => {
  const [order, setOrder] = useState('asc');
  const api = useApi();

  let columnsKeys = [];

  useEffect(() => {
    let orderableColumns = {};

    Object.keys(columns).map((col) => {
      col.orderable && (orderableColumns[col] = 'asc');
      columnsKeys.push(col);
    });
  });

  const changeOrder = (col) => {
    setOrder((prev) => prev == 'asc' ? 'desc' : 'asc');

    api.get(
      url,
      { 
        order: order, 
        orderBy: col 
      },
      {
        preserveState: true,
        replace: true,
      }
    );
  }

  return (
    <thead>
      <tr>
        {Object.keys(columns).map((col) => 
          <th 
            className={` px-4 text-left font-medium ${columns[col].orderable ? 'cursor-pointer' : ''}`}
            key={col}
            {...(columns[col].orderable && 
              {onClick: () => {
                  changeOrder(col);
                }
              }
            )}
          >
            {columns[col]?.name}
          </th>
        )}
      </tr>
    </thead>
  )
}

export default TableHeader