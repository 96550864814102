import Table from 'Components/Table/TableWrap';
import { useOutletContext } from "react-router-dom";
import { useEffect } from 'react';

const Services = () => {
    const [setTitle] = useOutletContext();

    useEffect(() => {
        setTitle('Сервисы');
    }, []);
    return (
        <Table 
            filters={
                {
                id: 'id',
                alias: 'alias',
                name: 'Название'
                } 
            }
            columns={
                { 
                    id: {
                        name: 'id',
                        orderable: true,
                    },
                    name: {
                        name: 'Название',
                        orderable: true,
                    },
                    description: {
                        name: 'Описание',
                        orderable: false,
                    },
                    alias: {
                        name: 'Alias', 
                        orderable: false,
                    },
                    last: {
                        name: ''
                    }
                }
            }
            dataUrl = 'admin/services/'
            cells = {['id', 'name', 'description', 'alias']}
            deleteItems = {true}
            editItems = {true}
        />
    );
}

export default Services;